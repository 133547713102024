/* Octopus Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Page
05.anomaly
*/

module.exports = {
  /* 01.General */

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Giriş Yap',
  'user.register': 'Kaydol',
  'user.forgot-password': 'Şifremi Unuttum',
  'user.email': 'E-posta',
  'user.name': 'Kullanıcı Adı',
  'user.password': 'Şifre',
  'user.forgot-password-question': 'Şifreni unuttun mu?',
  'user.fullname': 'Tam Ad',
  'user.login-button': 'GİRİŞ YAP',
  'user.register-button': 'KAYDOL',
  'user.reset-password-button': 'SIFIRLA',
  'user.buy': 'SATIN AL',
  'user.username': 'Kullanı Adı',
  'user.new-password-again': 'Yeni Şifre (Tekrar)',
  'user.new-password': 'Yeni Şifre',
  'user.reset-password': 'Şifreyi Sıfırla',
  'user.enterUsername': 'Lütfen kullanıcı adınızı girin',
  'user.enterPassword': 'Lütfen şifrenizi girin',
  'user.loginError': 'Giriş Hatası',
  'user.checkUsernamePassword': 'Lütfen Kullanıcı Adı / Şifrenizi kontrol edin',
  'user.octopusPanel': 'OCTOPUS PANEL',
  'user.useCredentials': 'Lütfen giriş yapmak için kullanıcı adı ve şifrenizi kullanın.',
  'user.forgotCredentials': 'Giriş bilgilerinizi hatırlamıyorsanız Codevo ile iletişime geçin.',
  'user.ldapServiceError': 'Ldap servisleri elde edilemedi.',
  'user.ldapServiceTitle': 'Ldap Servisi',

  /* 03.Menu */
  'menu.home': 'Ana Sayfa',
  'menu.app': 'Ana Sayfa',
  'menu.device': 'Cihaz',
  'menu.browser': 'Tarayıcı',
  'menu.script': 'Kod',
  'menu.application': 'Uygulama',
  'menu.malware': 'Zararlı Yazılım',
  'menu.reports': 'Raporlar',
  'menu.file': 'Dosya',
  'menu.admin': 'Yönetici',

  /* 04 Page */
  'pages.error-title': 'Ooops... görünüşe göre bir hata oldu!',
  'pages.error-code': 'Hata kodu',
  'pages.go-back-home': 'ANA SAYFAYA DÖN',
  'pages.orderby': 'Sırala : ',
  'pages.delete': 'Sil',
  'pages.another-action': 'Başka işlem',
  'pages.display-options': 'Seçenekleri Göster',
  'unauthorized.title': 'Yetkisiz Erişim Denemesi',
  'unauthorized.detail':
    'Erişmek istediğiniz sayfayı görüntülemeye yetkiniz yok. Yönetici ile iletişime geçin.',

  'pages.device.detail.general-tab': 'GENEL',
  'pages.device.detail.malware-tab': 'ZARARLI YAZILIM',
  'pages.device.detail.app-tab': 'YÜKLENEN UYGULAMALAR',
  'pages.device.detail.anomaly-tab': 'ANOMALİ',
  'pages.device.detail.general-users': 'KULLANICILAR',
  'pages.device.detail.general-users-cutoff-button': 'Güvenli Olarak İşaretle',
  'pages.device.detail.general-users-cutoff-again-button': 'Tekrar Güvenli Olarak İşaretle',
  'pages.device.detail.general-detail': 'CİHAZ DETAY',
  'pages.device.detail.general-app': 'UYGULAMALAR',
  'pages.device.detail.general-app-create-task': 'Cihaz Detayı Al',
  'pages.device.detail.general-app-apk-task': 'Apk Al',
  'pages.device.detail.general-app-create-push-task': 'Push Görevi Oluştur',
  'pages.device.detail.general-app-create-app-list-task':
    'Uygulama Listesini Yenile',
  'pages.device.detail.general-app-check-app-request':
    'Uygulama Varlığını Kontrol et',
  'pages.device.detail.general-app-device-parameters':
    'Cihaza Özel Parametreler',
  'pages.device.detail.general-app-create-app-list-task-again':
    'Tekrar Uygulama Listesi Push Görevi Oluştur',
  'pages.device.detail.check-app-package-name' : 'Paket Adı',
  'pages.device.detail.general-app-task-list': 'Görev Listesi',
  'pages.device.detail.general-app-cancel-task': 'Görevi İptal Et',
  'pages.device.runtime.list-tab': 'RUNTIME',
  'pages.device.task.list-tab': 'GÖREV',
  'pages.device.settings-tab': 'AYARLAR',
  'pages.device.settings-tag-added': 'Daha Önce Eklenen Taglar',
  'pages.device.no-tags': 'Tag bulunamadı.',
  'pages.device.settings-tag': 'Tag: ',
  'pages.device.settings-tag-first-seen': 'İlk Gözükme: ',
  'pages.device.settings-tag-last-seen': 'Son Gözükme: ',
  'pages.device.settings-tag-title': 'Yeni Tag',
  'pages.device.settings-tag-button': 'Tag Ekle',
  'pages.device.settings-tag-success': 'Tag eklendi.',
  'pages.device.settings-tag-fail': 'Tag eklenemedi.',
  'pages.device.settings-tag-delete-title': 'Tag Sil',
  'pages.device.settings-tag-delete-success': 'Tag silindi.',
  'pages.device.settings-tag-delete-fail': 'Tag silinemedi.',
  'pages.device.settings-get-add-tag-fail': 'Eklenebilir taglar elde edilemedi',
  'pages.device.settings-get-add-tag-title': 'Eklenebilir Taglar',
  'pages.device.settings-tag-info': 'BLOCK tagi ile cihazdan işlem yapılmasını engelleyebilir, CRASH tagi ile uygulamanın cihazda çalışmasını engeleyebilir veya istediğiniz bir tagin cihaza her runtimeda eklenmesini sağlayabilirsiniz.',
  'pages.device.task.new-tab': 'YENİ GÖREV',
  'pages.device.task.forms.app-select': 'Uygulama Push\'la',
  'pages.device.task.forms.text': 'Yazı Girdisi',
  'pages.device.task.forms.task-app': 'Uygulama',
  'pages.device.detail.runtime-detail': 'Detay Göster',
  'pages.device.detail.runtime-wifi': 'Wifi Info Tespit Edildi',
  'pages.device.detail.runtime-touch': 'Touch Tespit Edildi',
  'pages.device.detail.runtime-multiple-touch': 'Multiple Touch Type Tespit Edildi',
  'pages.device.detail.runtime-identifier-change': 'Identifier Change Tespit Edildi',
  'pages.browser.detail.general-detail': 'TARAYICI DETAY',
  'task.type.3': 'Yüklü Uygulama Listesi',
  'task.type.4': 'Uygulama Detay',
  'task.type.5': 'Log Gönder',
  'task.type.6': 'Apk Gönder',
  'task.type.7': 'Tüm Cihazları Tara',
  'task.type.8': 'Cihaz Parametrelerini Yenile',

  'pages.malware.detail.devices-tab': 'CİHAZLAR',
  'pages.malware.detail.management-tab': 'YÖNETİM',
  'pages.malware.detail.comments-tab': 'YORUMLAR',
  'pages.malware.detail.appAudits-tab': 'UYGULAMA DENETİMLERİ',

  'pages.script.detail.devices-tab': 'CİHAZLAR',
  'pages.script.detail.sourceCode-tab': 'KAYNAK KODU',
  'pages.script.detail.scriptAudits-tab': 'KOD DENETİMLERİ',

  'button.click-here': 'Ara',
  'button.show-more': 'Daha Fazla Göster',
  'button.show-details': 'Detayları Göster',
  'button.hide-details': 'Detayları Gizle',
  'device.search.placeholder': 'Bir arama sorgusu girin (örn. OCTOPUS-ID: id)',
  'device.search.detail.list.user-id': 'Cihazları user-id\'lere göre listeler',
  'device.search.detail.list.octopus-id': 'Cihazları octopus-id\'lere göre listeler',
  'device.search.detail.list.android-id': 'Cihazları android-id\'lere göre listeler',
  'device.search.detail.list.vendor-id': 'iOS cihazları vendor-id\'lere göre listeler',
  'device.search.detail.list.anomaly': 'Cihazları anomalilere göre listeler',
  'device.search.detail.list.emulator': 'Emulatörleri listeler',
  'device.search.detail.list.accessibility': 'Erişilebilirlik etkin olan cihazları listeler',
  'device.search.detail.list.runtimeHook': 'Runtime hook tespit edilmiş cihazları listeler',
  'device.search.detail.list.applicationName': 'Cihazları uygulama adlarına göre listeler',
  'device.search.detail.list.showApplicationNames': 'Uygulama adlarını göster',
  'device.search.detail.applicationNames': 'Uygulama Adları',

  'device.list.brand': 'Marka: ',
  'device.list.model': 'Model: ',
  'device.list.modelNo': 'Model No: ',
  'device.list.osVersion': 'OS Versiyon: ',
  'device.list.osApiLevel': 'OS API Seviyesi: ',
  'device.list.vendorId': 'Vendor Id: ',
  'device.list.androidId': 'Android Id: ',
  'device.list.octopusId': 'Octopus Id: ',
  'device.list.create': 'Oluşturma: ',
  'device.list.lastScan': 'Son Tarama: ',
  'device.list.applicationNames': 'Uygulama Adları: ',
  'device.list.noMalware': 'Zararlı Yazılım Yok',
  'device.list.installation': 'Kurulum: ',
  'device.list.lastSeen': 'Son Görülme: ',

  'device.detail.imei': 'Imei',
  'device.detail.wifiMac': 'Wifi Mac',
  'device.detail.manufacturer': 'Üretici',
  'device.detail.buildSerialNo': 'Build Seri No',
  'device.detail.deviceReport': 'Cihaz Raporu',
  'device.detail.package': 'Paket',
  'device.detail.versionName': 'Versiyon Adı',
  'device.detail.versionCode': 'Versiyon Kodu',
  'device.detail.octopusVersion': 'Octopus Versiyon',
  'device.detail.lastScan': 'Son Tarama',
  'device.detail.firstInstall': 'İlk Kurulum',
  'device.detail.lastUpdate': 'Son Güncelleme',
  'device.detail.identifier': 'Tanımlayıcı',
  'device.detail.copyClipboard': 'Kullanıcı ID\'lerini Panoya Kopyala',
  'device.detail.apkSize': 'Apk Boyutu: ',
  'device.detail.installation': 'Kurulum: ',
  'device.detail.firstSeen': 'İlk Gözükme: ',
  'device.detail.update': 'Güncelleme: ',
  'device.detail.lastSeen': 'Son Gözükme: ',
  'device.detail.sources': 'Kaynaklar',
  'device.detail.entries': 'girdi',
  'device.detail.show': 'Göster ...',
  'device.detail.accessibilityActive': 'Erişilebilirlik Aktif',
  'device.detail.noEntries': 'Girdi yok',
  'device.detail.iconSHA1': 'İkon SHA1:',
  'device.detail.apkSha256Hash': 'Apk Sha256 Hash:',
  'device.detail.certificateSHA1': 'Sertifika SHA1:',
  'device.detail.primaryCertHash': 'Birincil Sertifika Hash:',
  'device.detail.permissions': 'İzinler',
  'device.detail.installed': 'KURULU',
  'device.detail.uninstalled': 'KALDIRILDI',
  'device.detail.runtime': 'Runtime',

  'sort.createASC': 'Oluşturma Tarihi | ART',
  'sort.createDESC': 'Oluşturma Tarihi | AZAL',
  'sort.firstSeenDateDESC': 'İlk Gözükme Tarihi | AZAL',
  'sort.firstSeenDateASC': 'İlk Gözükme Tarihi | ART',
  'sort.lastSeenDateDESC': 'Son Gözükme Tarihi | AZAL',
  'sort.lastSeenDateASC': 'Son Gözükme Tarihi | ART',
  'sort.installationTimeDESC': 'Kurulum Tarihi | AZAL',
  'sort.installationTimeASC': 'Kurulum Tarihi | ART',
  'sort.firstSeenDate': 'İlk Gözükme Tarihi',
  'sort.firstSeen': 'İlk Gözükme',
  'sort.lastExecutionTimeDESC': 'Son Uygulanma Tarihi | AZAL',

  'malware.category.BLACKLISTED': 'KARA_LİSTE',
  'malware.category.MALWARE': 'ZARARLI_YAZILIM',
  'malware.category.TARGETED_MALWARE': 'HEDEFLİ_ZARARLI_YAZILIM',
  'malware.category.WHITELISTED': 'BEYAZ_LİSTE',
  'malware.category.UNKNOWN': 'BİLİNMİYOR',
  'malware.category.RANSOMWARE': 'FİDYE_VİRÜSÜ',
  'malware.category.PHISHING': 'YEMLEME',
  'malware.category.TROJAN': 'TRUVA',
  'malware.category.FRAUDWARE': 'SAHTE_YAZILIM',
  'malware.category.TOLL_FRAUD': 'ÜCRET_DOLANDIRICILIĞI',
  'malware.category.WAP_FRAUD': 'WAP_DOLANDIRICILIĞI',
  'malware.category.CALL_FRAUD': 'ARAMA_DOLANDIRICILIĞI',
  'malware.category.BACKDOOR': 'ARKA_KAPI',
  'malware.category.SPYWARE': 'CASUS_YAZILIM',
  'malware.category.GENERIC_MALWARE': 'GENEL_ZARARLI_YAZILIM',
  'malware.category.HARMFUL_SITE': 'ZARARLI_SİTE',
  'malware.category.WINDOWS_MALWARE': 'WINDOWS_ZARARLI_YAZILIMI',
  'malware.category.HOSTILE_DOWNLOADER': 'ZARARLI_İNDİREN',
  'malware.category.NON_ANDOID_THREAT': 'ANDROID_DIŞI_TEHDİT',
  'malware.category.ROOTING': 'ROOTING',
  'malware.category.PRIVILAGE_ESCALATION': 'AYRICALIK_YÜKSELTME',
  'malware.category.TRACKING': 'TAKİP',
  'malware.category.SPAM': 'SPAM',
  'malware.category.DENIAL_OF_SERVICE': 'SERVİS_DIŞI_BIRAKMA',
  'malware.category.DATA_COLLECTION': 'VERİ_TOPLAMA',
  'malware.category.DO NOT OVERRIDE': 'ÜSTÜNE_YAZMA',

  'device.detail.createApkTask': 'Apk Görevi Oluştur',
  'device.detail.createApkTaskMessage': 'Cihaza sessiz push gönderilecek ve apk elde edilecek. Onaylıyor musunuz?',
  'device.detail.createAppDetailTask': 'Uygulama Detay Görevi Oluştur',
  'device.detail.createAppDetailTaskMessage': 'Cihaza sessiz push gönderilecek ve uygulama detayları elde edilecek.',
  'device.detail.createAppListTask': 'Uygulama Listesi Görevi Oluştur',
  'device.detail.createAppListTaskMessage': 'Cihaza sessiz push gönderilecek ve kurulu uygulamalar bilgisi elde edilecek. Onaylıyor musunuz?',
  'device.detail.includeAppIcon': 'Uygulama ikonunu dahil et',
  'device.detail.includePermissions': 'İzinleri dahil et',
  'device.detail.includeApkHash': 'Apk hash\'i dahil et',
  'device.detail.checkApplication': 'Uygulama Kontrol Et',
  'device.detail.check': 'Kontrol Et',
  'device.detail.packageNameEmpty': 'Paket adı boş verilemez.',
  'device.detail.appExists': 'Bu uygulama bu cihazda mevcut.',
  'device.detail.appDoesNotExist': 'Bu uygulama bu cihazda mevcut değil.',
  'device.detail.bankName': 'Banka uygulamaları aranamaz.',
  'device.detail.consumedRuntime': 'Yük başına tüketilen runtime',
  'device.detail.monthLimit': 'Ay limiti',
  'device.detail.loadMoreAnomalies': 'Daha Fazla Anomali Yükle',
  'device.detail.lastRuntimes': 'Son Runtimelar',
  'device.detail.historicalRuntimes': 'Geçmiş Runtimelar',
  'device.detail.application': 'Uygulama: ',
  'device.detail.create': 'Oluşturma: ',
  'device.detail.lastModified': 'Son Değişiklik: ',
  'device.detail.tags': 'Taglar',
  'device.detail.createTask': 'Görev Oluştur',
  'device.detail.getDeviceLog': 'Octopus Log Al',
  'device.detail.getCustomLog': 'Seal Log Al',
  'device.detail.selectApp': 'Uygulama Seç',
  'device.detail.scanAllDevices': 'Tüm Cihazları Tara',
  'device.detail.type': 'Tür: ',
  'device.detail.status': 'Durum: ',
  'device.detail.createDate': 'Oluşturma Tarihi: ',
  'device.detail.body': 'Gövde: ',
  'device.detail.createButton': 'Oluştur',
  'device.detail.downloadAPK': 'APK İndir',
  'device.detail.downloadLog': 'Log İndir',
  'device.detail.cutoffDate': 'Son Güvenli İşaretleme: ',
  'device.detail.associationDate': 'Eşleştirme Tarihi: ',
  'device.detail.lastQueryDate': 'Son Sorgu Tarihi: ',
  'device.detail.markUserSafeMessage': 'Bu kullanıcıyı güvenli olarak işaretlemek istediğinize emin misiniz?',
  'device.detail.lastCall': 'Son Arama: ',
  'device.detail.lastIncomingCall': 'Son Gelen Arama: ',

  'device.detail.taskError': 'Görev Hatası',
  'device.detail.taskErrorMessage': 'Octopus SDK versiyonu bu görev ile uyumlu değil.',
  'device.detail.taskErrorMessage2': 'Bunu yapabilmek için Octopus SDK versiyonu yeterli değil.',
  'device.detail.cancelTask': 'Görevi İptal Et',
  'device.detail.taskCancelMessage': 'Görev iptal edilecek. Onaylıyor musunuz?',
  'device.detail.selectionError': 'Seçim Hatası',
  'device.detail.selectionErrorMessage': 'Lütfen bir uygulama seçin.',

  'app.search.placeholder': 'Bir arama sorgusu girin (örn. PKG: com.lorem.ipsum)',
  'app.search.detail.list.category': 'Girilen kategori ismiyle aynı olan PHA\'ları listeler. Geçerli kategori adları : UNKNOWN, TARGETED_MALWARE, WHITELISTED, BLACKLISTED...',
  'app.search.detail.list.pkg': 'Girilen paket adıyla aynı olan PHA\'ları listeler.',
  'app.search.detail.list.apk-hash-hex': 'Girilen apk hash (hex) ile aynı olan uygulamaları bulur.',
  'app.search.detail.list.icon-sha1-hex': 'Girilen logo sha1 hash (hex) ile aynı olan PHA\'ları listeler.',
  'app.search.detail.list.cert-sha1-hex': 'Girilen sertifika sha1 (hex) parmak iziyle aynı olan PHA\'ları listeler.',
  'app.search.detail.list.label-like': 'Girilen isme benzer olan PHA\'ları listeler.',
  'app.search.detail.list.permission': 'Girilen izne sahip olan PHA\'ları listeler.',
  'app.search.detail.list.tag': 'Girilen tag\'a sahip olan PHA\'ları listeler. (Octopus.ACCESSIBILITY, Octopus.DEBUG_KEY etc ...)',
  'app.search.detail.list.day-range': 'Girilen gün sayısı aralığında oluşturulmuş PHA\'ları listeler.',

  'app.list.firstSeenDate': 'İlk Gözükme Tarihi : ',
  'app.list.apkHash': 'Apk Hash: ',
  'app.list.iconSHA1': 'İkon SHA1: ',
  'app.list.certificateSHA1': 'Sertifika SHA1: ',

  'app.detail.appName': 'Uygulama Adı',
  'app.detail.packageName': 'Paket Adı',
  'app.detail.apkHash': 'Apk Hash',
  'app.detail.iconSHA1': 'İkon SHA-1',
  'app.detail.certificates': 'Sertifikalar',
  'app.detail.apkSize': 'Apk Boyutu',
  'app.detail.category': 'Kategori',
  'app.detail.createDate': 'Oluşturma Tarihi',
  'app.detail.lastModifiedDate': 'Son Değişiklik Tarihi',
  'app.detail.permissions': 'İzinler',
  'app.detail.entries': 'girdi',
  'app.detail.show': 'Göster ...',
  'app.detail.manifest': 'Manifesto',
  'app.detail.androidManifestFile': 'Android Manifesto Dosyası',
  'app.detail.tags': 'Taglar',
  'app.detail.users': 'Kullanıcılar',
  'app.detail.usersAffected': 'kullanıcı etkilendi',
  'app.detail.noEntries': 'Girdi yok',
  'app.detail.noAffectedUsers': 'Etkilenen kullanıcı yok',
  'app.detail.brand': 'Marka: ',
  'app.detail.model': 'Model: ',
  'app.detail.osVersion': 'OS Versiyon: ',
  'app.detail.osApiLevel': 'OS API Seviyesi: ',
  'app.detail.securityPatchLevel': 'Güvenlik Yama Seviyesi: ',
  'app.detail.androidId': 'Android Id: ',
  'app.detail.octopusId': 'Octopus Id: ',
  'app.detail.firstSeen': 'İlk Gözükme:',
  'app.detail.lastSeen': 'Son Gözükme: ',
  'app.detail.installation': 'Kurulum: ',
  'app.detail.malwareCategoryMessage': 'Zararlı yazılım kategorisini buradan değiştirebilirsiniz.',
  'app.detail.save': 'Kaydet',
  'app.detail.malwareChangeMessage': 'Zararlı yazılım kategorisini değiştirmek istediğinize emin misiniz?',
  'app.detail.currentValue': 'Güncel Değer: ',
  'app.detail.oldValue': 'Eski Değer: ',
  'app.detail.field': 'Alan: ',
  'app.detail.changeDate': 'Değiştirme Tarihi: ',
  'app.detail.user': 'Kullanıcı: ',

  'browser.search.placeholder': 'Bir arama sorgusu girin (örn. OCTOPUS-ID : id)',
  'browser.search.detail.list.user-id': 'Cihazları user-id\'lere göre listeler',
  'browser.search.detail.list.octopus-id': 'Cihazları octopus-id\'lere göre listeler',
  'browser.search.detail.list.anomaly': 'Cihazları anomalilere göre listeler',
  'browser.search.detail.list.emulator': 'Emülatörleri listeler',
  'browser.search.detail.list.accessibility': 'Erişilebilirlik etkin olan cihazları listeler',
  'browser.search.detail.list.webDriver': 'Web driver tespit edilmiş cihazları listeler',

  'browser.list.brand': 'Marka: ',
  'browser.list.version': 'Versiyon: ',
  'browser.list.os': 'OS: ',
  'browser.list.engine': 'Motor: ',
  'browser.list.octopusId': 'Octopus Id: ',
  'browser.list.create': 'Oluşturma: ',
  'browser.list.lastScan': 'Son Tarama: ',
  'browser.list.noMalware': 'Zararlı Yazılım Yok',
  'browser.list.firstSeen': 'İlk Gözükme: ',
  'browser.list.lastSeen': 'Son Gözükme: ',
  'browser.list.installation': 'Kurulum: ',
  'browser.list.model': 'Model: ',

  'browser.detail.manufacturer': 'Üretici',
  'browser.detail.platform': 'Platform',
  'browser.detail.timeZone': 'Zaman Dilimi',
  'browser.detail.canvas': 'Canvas',
  'browser.detail.browserHash': 'Tarayıcı Hash',
  'browser.detail.userAgent': 'Kullanıcı Ajanı',
  'browser.detail.appVersion': 'Uygulama Versiyonu',
  'browser.detail.cpuCores': 'İşlemci Çekirdeği',
  'browser.detail.deviceMemory': 'Cihaz Hafızası',
  'browser.detail.maxTouchPoints': 'Maksimum Dokunma Noktası',
  'browser.detail.deviceReport': 'Cihaz Raporu',
  'browser.detail.host': 'Host',
  'browser.detail.spiderVersion': 'Spider Versiyonu',
  'browser.detail.lastScan': 'Son Tarama',
  'browser.detail.firstVisit': 'İlk Ziyaret',
  'browser.detail.copyClipboard': 'Kullanıcı ID\'lerini Panoya Kopyala',
  'browser.detail.markUserSafeMessage': 'Bu kullanıcıyı güvenli olarak işaretlemek istediğinize emin misiniz?',
  'browser.detail.unspecified': 'Belirtilmedi',
  'browser.detail.scriptSize': 'Kod Boyutu: ',
  'browser.detail.firstSeen': 'İlk Gözükme: ',
  'browser.detail.lastSeen': 'Son Gözükme: ',
  'browser.detail.installed': 'KURULU',
  'browser.detail.uninstalled': 'KALDIRILDI',
  'browser.detail.sha256': 'SHA256: ',
  'browser.detail.sha1': 'SHA1: ',
  'browser.detail.md5': 'MD5: ',
  'browser.detail.ssdeep': 'SSDEEP: ',

  'script.list.firstSeenDate': 'İlk Gözükme Tarihi: ',
  'script.list.sha256': 'SHA256: ',
  'script.list.sha1': 'SHA1: ',
  'script.list.md5': 'MD5: ',
  'script.detail.label': 'Ad',
  'script.detail.source': 'Kaynak',
  'script.detail.sha256': 'SHA256',
  'script.detail.sha1': 'SHA1',
  'script.detail.md5': 'MD5',
  'script.detail.ssdeep': 'SSDEEP',
  'script.detail.scriptSize': 'Script Boyutu',
  'script.detail.category': 'Kategori',
  'script.detail.createDate': 'Oluşturma Tarihi',
  'script.detail.lastModifiedDate': 'Son Değişiklik Tarihi',
  'script.detail.tags': 'Taglar',
  'script.detail.entries': 'girdi',
  'script.detail.show': 'Göster ...',
  'script.detail.noEntries': 'Girdi yok',
  'script.detail.users': 'Kullanıcılar',
  'script.detail.noAffectedUsers': 'Etkilenen kullanıcı yok',
  'script.detail.usersAffected': 'kullanıcı etkilendi',
  'script.detail.brand': 'Marka: ',
  'script.detail.version': 'Versiyon: ',
  'script.detail.osVersion': 'OS Versiyonu: ',
  'script.detail.engine': 'Motor: ',
  'script.detail.octopusId': 'Octopus Id: ',
  'script.detail.firstSeen': 'İlk Gözükme: ',
  'script.detail.lastSeen': 'Son Gözükme: ',
  'script.detail.copyToClipboard': 'Panoya Kopyala',
  'script.detail.copied': 'Kopyalandı!',
  'script.detail.download': 'İndir',
  'script.detail.highlight': 'Vurgula',
  'script.detail.fetchSourceCode': 'Kaynak Kodunu Al',
  'script.detail.changeCategoryMessage': 'Zararlı yazılım kategorisini aşağıdan seçerek değiştirebilirsin ve yeni bir isim ekleyebilirsin.',
  'script.detail.save': 'Kaydet',
  'script.detail.malwareUpdateMessage': 'Zararlı yazılımı güncellemek istediğinize emin misiniz?',

  'report.availableReports': 'Mevcut Raporlar',
  'report.reportName': 'Rapor Adı : ',
  'report.generateNewReport': 'YENİ RAPOR OLUŞTUR',
  'report.previouslyGeneratedReports': 'Önceden Oluşturulmuş Raporlar',
  'report.deleteReports': 'Delete Reports',
  'report.deleteReportsMessage': 'Tüm raporlar silinecek. Onaylıyor musunuz?',
  'report.refreshPageWarning': 'Sayfa Yenileme Uyarısı',
  'report.refreshPageWarningMessage': 'Lütfen raporlar için sayfayı yenileyin',
  'report.validateName': 'Lütfen bir değer girin',

  'file.name': 'Name: ',
  'file.size': 'Size: ',
  'file.status': 'Status: ',
  'file.id': 'Id: ',
  'file.lastExecutionTime': 'Son Uygulanma Tarihi: ',

  'users.createUser': 'Kullanıcı Oluştur',
  'users.userName': 'Kullanıcı Adı: ',
  'users.name': 'Ad: ',
  'users.email': 'E-posta: ',
  'users.admin': 'YÖNETİCİ',
  'users.editor': 'EDİTÖR',
  'users.reviewer': 'İNCELEYİCİ',
  'users.developer': 'GELİŞTİRİCİ',
  'users.authorizer': 'YETKİ VEREN',
  'users.manager': 'SİSTEM YÖNETİCİSİ',
  'users.userViewer': 'KULLANICI İNCELEYİCİ',
  'users.ruleViewer': 'KURAL İNCELEYİCİ',
  'users.moderator': 'MODERATÖR',
  'role.0': 'İnceleyici',
  'role.1': 'Editör',
  'role.2': 'Yönetici',
  'role.3': 'Geliştirici',
  'role.4': 'Yetki Veren',
  'role.5': 'Sistem Yöneticisi',
  'role.6': 'Kullanıcı İnceleyici',
  'role.7': 'Kural İnceleyici',
  'role.8': 'Moderatör',
  'users.createdBy': 'Oluşturan: ',
  'users.createDate': 'Oluşturma Tarihi: ',
  'users.lastSuccessfulLogin': 'Son Başarılı Giriş: ',
  'users.lastFailedLogin': 'Son Başarısız Giriş: ',
  'users.edit': 'Düzenle',
  'users.delete': 'Sil',
  'users.create': 'Oluştur',
  'users.editUserRole': 'Kullanıcı Rolünü Düzenle',
  'users.save': 'Kaydet',
  'users.deleteUser': 'Kullanıcıyı Sil',
  'users.deleteUserMessagePart1': ' ',
  'users.deleteUserMessagePart2': ' kullanıcısı silinecek. Onaylıyor musunuz?',
  'users.createUserError': 'Kullanıcı Oluşturma Hatası',
  'users.createUserErrorMessage': 'Kullanıcı Adını Doldurun',
  'users.createUserErrorMessage2': 'Lütfen yeni kullanıcı bilgisini kontrol edin',
  'users.editUserError': 'Kullanıcı Düzenleme Hatası',
  'users.editUserErrorMessage': 'Aynı kullanıcı rolü seçildi',

  'rule.changeRuleStatus': 'Kural Durumunu Değiştir',
  'rule.changeRuleStatusMessage': 'Bu kuralın durumunu değiştirmek istediğinize emin misiniz?',
  'rule.pipelineUpdate': 'Kural Güncellemesi',
  'rule.pipelineUpdateMessage': 'Kural durumu güncellendi.',
  'rule.pipelineUpdateError': 'Kural Güncelleme Hatası',
  'rule.messageUpdate': 'Mesaj Güncellemesi',
  'rule.messageUpdateMessage': 'Kural mesajı güncellendi.',
  'rule.updateFail': 'Güncelleme başarısız oldu!',
  'rule.cacheRefreshed': 'Önbellek yenilendi.',
  'rule.cacheError': 'Önbellek Hatası',
  'rule.cacheFail': 'Önbellek başarısız oldu!',
  'rule.editMessage': 'Mesajı Düzenle',
  'rule.apply': 'Uygula',
  'rule.emptyMessage': 'Mesaj boş bırakılamaz.',
  'rule.changeButtonAction': 'Buton Aksiyonunu Değiştir',
  'rule.changeButtonActionMessage': 'Bu kuralın buton aksiyonunu değiştirmek istediğinize emin misiniz?',
  'rule.buttonActionUpdateMessage': 'Buton aksiyonu güncellendi.',

  'pages.admin.user-tab': 'KULLANICILAR',
  'pages.admin.profile-tab': 'PROFİL',
  'pages.admin.newuser-tab': 'YENI KULLANICI',
  'pages.admin.other-tab': 'DİĞER',
  'pages.admin.user-report-button': 'Kullanıcı Raporu',
  'pages.admin.create-user-button': 'Kullanıcı Oluştur',
  'pages.admin.create-user-username': 'Kullanıcı Adı',
  'pages.admin.create-user-password': 'Şifre',
  'pages.admin.create-user-email': 'E-posta',
  'pages.admin.create-user-firstname': 'Ad',
  'pages.admin.create-user-lastname': 'Soyad',
  'pages.admin.edit-user': 'Düzenle',
  'pages.admin.edit-delete': 'Sil',
  'pages.admin.file-operations-tab': 'DOSYA İŞLEMLERI',
  'pages.admin.pipeline-operations-tab': 'KURAL AYARLARI',
  'pages.admin.request-log-tab': 'İSTEK KAYITLARI',
  'pages.admin.parameter-tab': 'PARAMETRELER',
  'pages.menu.filter': 'FİLTRELE',
  'pages.menu.pipeline': 'AKSİYON',
  'pages.request-log.status': 'Durum',
  'pages.request-log.user-name': 'Kullanıcı Adı',
  'pages.request-log.url': 'URL',
  'pages.request-log.method': 'Metod',
  'pages.request-log.start-date': 'Başlangıç Tarihi',
  'pages.request-log.end-date': 'Bitiş Tarihi',
  'pages.request-log.request': 'Uygula',
  'pages.pipeline.apply': 'Bütün Değişiklikleri Uygula',
  'pages.no-data': 'Kayıt bulunamadı.',
  'pages.no': 'Hayır',
  'pages.yes': 'Evet',
  'pages.cancel': 'İptal et',
  'pages.close': 'Kapat',
  'pages.confirmation': 'Onay',
  'pages.requestloglist.table.user': 'Kullanıcı',
  'pages.requestloglist.table.method': 'Metod',
  'pages.requestloglist.table.context-root': 'Context Root',
  'pages.requestloglist.table.url': 'Url',
  'pages.requestloglist.table.description': 'Açıklama',
  'pages.requestloglist.table.start-date': 'Başlangıç Tarihi',
  'pages.requestloglist.table.elapsed-time': 'Geçen Zaman (ms)',
  'pages.requestloglist.table.status': 'Durum',
  'pages.requestloglist.table.detail': 'Detay',
  'pages.requestloglist.menu.start-date': 'Başlangıç Tarihi',
  'pages.requestloglist.menu.end-date': 'Bitiş Tarihi',
  'pages.requestloglist.menu.user': 'Kullanıcı',
  'pages.requestloglist.menu.method': 'Metod',
  'pages.requestloglist.menu.context-root': 'Context Root',
  'pages.requestloglist.menu.url': 'Url',
  'pages.requestloglist.menu.status': 'Durum',
  'pages.requestlog.detailError.title': 'İstek Kayıtları Hatası',
  'pages.requestlog.detailError.message': 'İstek detayları elde edilemedi',
  'pages.requestlog.requestDetail': 'İstek Detayı',
  'pages.requestloglist.desc.login': 'Panele login olunduğunda atılan istek',
  'pages.requestloglist.desc.parameter': 'Her login sonrası otomatik atılır. Parametre kontrolü yapılır. Örneğin ikonu blurlanacak uygulama isim listesini alır.',
  'pages.requestloglist.desc.getDeviceList': 'Device menüsüne tıklanıp cihaz listeleme ekranı açıldığında atılan istek',
  'pages.requestloglist.desc.getDeviceDetail': 'Cihaz detay ekranı açıldığında atılan istek',
  'pages.requestloglist.desc.getDeviceAppList': 'Cihaz detay ekranında YÜKLENEN UYGULAMALAR tabı açıldığında atılan istek.',
  'pages.requestloglist.desc.getDevicePhaList': 'Cihaz detay ZARARLI YAZILIM tabı açıldığında atılan istek.',
  'pages.requestloglist.desc.createAppListTask': 'YÜKLENEN UYGULAMALAR ekranında uygulama listesini almak için butona tıklandığında atılan istek.',
  'pages.requestloglist.desc.getDeviceAnomalies_v2': 'Cihaz detay ekranında ANOMALİ tabı açıldığında atılan istek.',
  'pages.requestloglist.desc.deviceApplicationRuntime_v2': 'Cihaz detay ekranında RUNTIME tabı açıldığında atılan istek.',
  'pages.requestloglist.desc.getTasks': 'Cihaz detay ekranında GÖREV tabı açıldığında atılan istek.',
  'pages.requestloglist.desc.createLogTask': 'GÖREV ekranında cihazdan log almak için yeni task oluşturulduğunda atılan istek.',
  'pages.requestloglist.desc.getPhaList': 'Uygulama menüsüne tıklandığında zararlı yazılımları almak için atılan istek',
  'pages.requestloglist.desc.getPhaDetail': 'Uygulama menüsünden bir zararlı yazılımın detayı açıldığında atılan istek.',
  'pages.requestloglist.desc.getPhaDevices': 'Zararlı yazılım detay ekranı açıldığında zararlının yüklü olduğu cihazları almak için atılan istek.',
  'pages.requestloglist.desc.appAudit/appId': 'Uygulama detay ekranına girildiğinde UYGULAMA DENETİMLERİ tabındaki bilgileri çekmek için atılır.',
  'pages.requestloglist.desc.updatePhaCategory': 'Uygulama detay ekranında YÖNETİM tabında uygulamanın kategorisini değiştirmek için atılır.',
  'pages.requestloglist.desc.reportDefinitions': 'Rapor menüsüne tıklanıldığında atılan istek',
  'pages.requestloglist.desc.previousReports': 'Rapor menüsünde herhangi bir rapora tıklanıldığında atılan istek.',
  'pages.requestloglist.desc.generateReport': 'Yeni rapor oluşturulduğunda atılan istek.',
  'pages.requestloglist.desc.uploadJSONFile': 'Admin menüsünden dosya yüklendiğinde atılan istek',
  'pages.requestloglist.desc.getFiles': 'Admin menüsünden yüklenen dosyalar listelendiğinde atılan istek.',
  'pages.requestloglist.desc.executionResult': 'Admin menüsünden yüklenen dosyanın sonucu download edildiğinde atılan istek',
  'pages.requestloglist.desc.requestLog': 'Admin menüsünde İSTEK KAYITLARI ekranı açıldığında atılan istek.',
  'pages.requestloglist.desc.getUsers': 'Admin menüsünde kullanıcılar listelendiğinde atılan istek',
  'pages.requestloglist.desc.createUser': 'Admin menüsünden yeni kullanıcı oluşturulduğunda atılan istek. Roller: İnceleyici=0, Editör=1, Yönetici=2',
  'pages.requestloglist.desc.editUser': 'Admin menüsünde bir kullanıcının rolü değiştiğinde atılan istek',
  'pages.requestloglist.desc.deleteUser': 'Admin menüsünde bir kullanıcı silindiğinde atılan istek',
  'pages.requestloglist.desc.pipelineOperationList': 'Admin menüsünde KURAL AYARLARI ekranı açıldığında atılan istek.',
  'pages.requestloglist.desc.updatePipelineEnableStatus': 'KURAL AYARLARI ekranında bir kural aktifleştirildiğinde veya pasifleştirildiğinde atılır.',
  'pages.requestloglist.desc.increaseCacheVersion': 'KURAL AYARLARI ekranında "Tüm Değişiklikleri Uygula" butonuna tıklanıldığında atılır.',
  'pages.requestloglist.desc.getDeviceSpecificTags': 'Cihaz detay ekranında AYARLAR tabında cihaza eklenmiş tagları listelemek için atılır.',
  'pages.requestloglist.desc.getTagsToAdd': 'Cihaz detay ekranında AYARLAR tabında cihaza eklenebilecek tagları listelemek için atılır.',
  'pages.requestloglist.desc.addDeviceSpecificTag': 'Cihaz detay ekranında AYARLAR tabında cihaza yeni bir tag eklemek için atılır.',
  'pages.requestloglist.desc.deleteDeviceSpecificTag': 'Cihaz detay ekranında AYARLAR tabında cihazdan bir tagı silmek için atılır.',
  'pages.requestloglist.desc.getBrowserList': 'Browser menüsüne tıklanıp tarayıcı listeleme ekranı açıldığında atılan istek',
  'pages.requestloglist.desc.getLdapServices': 'Login sayfasında LDAP servislerini elde etmek için atılan istek',
  'pages.requestloglist.desc.getPhsList': 'Script menüsüne tıklanıp script listeleme ekranı açıldığında atılan istek',
  'pages.requestloglist.desc.deviceDiagnose': 'Cihaz detay ekranında cihaz raporu elde etmek için atılan istek',
  'pages.requestloglist.desc.pipelineOperations': 'Rule config sayfasında kuralları elde etmek için atılan istek',
  'pages.requestloglist.desc.getBrowserDetail': 'Tarayıcı detay ekranı açıldığında atılan istek',
  'pages.requestloglist.desc.browserDiagnose': 'Tarayıcı detay ekranında tarayıcı raporu elde etmek için atılan istek',
  'pages.requestloglist.desc.getBrowserAnomalies_v2': 'Tarayıcı detay ekranında ANOMALİ tabı açıldığında atılan istek.',
  'pages.requestloglist.desc.browserApplicationRuntime_v2': 'Tarayıcı detay ekranında RUNTIME tabı açıldığında atılan istek.',
  'pages.requestloglist.desc.getDevicePhsList': 'Tarayıcı detay ekranında ZARARLI YAZILIM tabı açıldığında atılan istek.',
  'pages.requestloglist.desc.registerReportDefinition': 'Rapor sayfası için yeni bir rapor tanımı oluşturmak için atılan istek.',
  'pages.requestloglist.desc.downloadPreviousReport': 'Rapor sayfasında önceden oluşturulmuş raporları indirmek için atılan istek.',

  'requestLog.detailMessage.updatePipelineEnableStatus': 'ID\'si {{1}} olan kural {{2}}',
  'requestLog.detailMessage.updatePipelineEnableStatus.true': 'aktifleştirildi.',
  'requestLog.detailMessage.updatePipelineEnableStatus.false': 'deaktive edildi.',
  'requestLog.detailMessage.createUser': '{{1}} isimli yeni kullanıcı, rolü {{2}} olarak oluşturuldu.',
  'requestLog.detailMessage.editUser': '{{1}} isimli kullanıcının rolü {{2}} olarak değiştirildi.',
  'requestLog.detailMessage.deleteUser': '{{1}} isimli kullanıcı silindi.',
  'requestLog.detailMessage.addSpecificTag': 'Octopus ID\'si {{1}} olan cihaza {{2}} tagı eklendi.',
  'requestLog.detailMessage.deleteSpecificTag': 'Octopus ID\'si {{1}} olan cihazdan {{2}} tagı silindi.',

  'pages.parameterslist.table.name': 'Ad',
  'pages.parameterslist.table.value': 'Değer',
  'pages.parameterslist.table.desc': 'Açıklama',
  'pages.parameterslist.table.target': 'Hedef',
  'pages.parameterslist.table.pilotPercentage': 'Pilot',
  'pages.parameterslist.target.GENERAL': 'PLATFORM',
  'pages.parameterslist.desc.ess': 'Android cihazlarda uzaktan erişimde ekran karartmayı sağlar. 3 farklı durum için aktifleştirilebilir: (Uzaktan Erişim Uygulaması / Zararlı Yazılım / Erişilebilirlik Yetkisi Alan Uygulama). Bu durumların farklı kombinasyonlarına göre aktifleştirilebilir. 3 bit üzerinden değer alır. 111 şeklinde ise değer 7 olur. 3 durum da aktifleşmiş olur.',
  'pages.parameterslist.desc.vcss': 'Whatsapp gibi uygulamalarda yapılan ekran paylaşımını bildirir.',
  'pages.parameterslist.desc.ebss': 'Uygulama arka plandaysa ekranı bulanıklaştırır.',
  'pages.parameterslist.desc.callst': 'Android arama verisi için kullanılan parametre. 1, 3 veya 7 değerlerini alır. Eski SDK versiyonlarında kullanılır. 1 arama verisini alır, 3 veriyi backend\'e iletir, 7 veriyi backend\'e iletir ve SDK aracılığıyla mobil uygulamayı bilgilendirir.',
  'pages.parameterslist.desc.callstfr': 'Yeni SDK lar için kullanılan haline 2 parametre daha eklendi. Örnek callstfr: "3; 120; 300". İlk değer callst ile aynı. İkinci değer Octopus backend\i arama verisi ile bilgilendirme periyodu. Yeni arama sayısı farketmeksizin her x saniyede bir bilgilendirilecek. Üçüncü değer mobil bankacılık uygulamasını SDK aracılığıyla arama verisi ile bilgilendirme periyodu.',
  'pages.parameterslist.desc.ham': '49 ise bilinmeyen kaynaklardan yüklenen tüm uygulamalar için aktif. 56 ise tüm cihazlar için aktif.',
  'pages.parameterslist.desc.siaabf': 'Bloom filter kullanmak için cihaz uygulamalarını erişilip erişilemeyeceği değeri.',
  'pages.parameterslist.desc.ucc': 'Checkup cache kullanma değeri. True ise, eğer şuanki checkup isteği bir öncekiyle aynıysa, cachedeki değer döndürülür.',
  'pages.parameterslist.desc.mcc': 'Anti keylogging parametresi. Android cihazlarda bilinmeyen kaynaktan indirilen klavye bilgilerini almak için kullanılır.',
  'pages.parameterslist.desc.immo': 'Anti keylogging parametresi. Android cihazlarda bilinmeyen kaynaktan indirilen klavye bilgilerini almak için kullanılır.',
  'pages.parameterslist.desc.tchintdvcid': '(TOUCH_HINT_DEVICE_ID)',
  'pages.parameterslist.desc.tchintdvcnm': '(TOUCH_INTERCEPTOR_DEVICE_NAME)',
  'pages.parameterslist.desc.tchintsz': '(TOUCH_INTERCEPTOR_SIZE)',
  'pages.parameterslist.desc.tchvnt': '(TOUCH_EVENT)',
  'pages.parameterslist.name.ess': 'Enable Screen Security',
  'pages.parameterslist.name.vcss': 'Voip Call Screen Security',
  'pages.parameterslist.name.ebss': 'Enable Background Screen Security',
  'pages.parameterslist.name.callst': 'Call State',
  'pages.parameterslist.name.callstfr': 'Call State with Frequency',
  'pages.parameterslist.name.ham': 'Android Anti Keylogging Parameter',
  'pages.parameterslist.name.siaabf': 'Send Installed Apps as Bloom Filter',
  'pages.parameterslist.name.ucc': 'Update Checkup Cache',
  'pages.parameterslist.name.mcc': 'Metric Collection',
  'pages.parameterslist.name.immo': 'Input Methods Metric Options',
  'pages.parameterslist.name.tchintdvcid': 'Touch Hint Device ID',
  'pages.parameterslist.name.tchintdvcnm': 'Touch Interceptor Device Name',
  'pages.parameterslist.name.tchintsz': 'Touch Interceptor Size',
  'pages.parameterslist.name.tchvnt': 'Touch Event',
  'pages.parameterslist.value.ess.0': 'Hiçbiri',
  'pages.parameterslist.value.ess.1': 'Erişilebilirlik Yetkisi Alan Uygulama',
  'pages.parameterslist.value.ess.2': 'Zararlı Yazılım',
  'pages.parameterslist.value.ess.3': 'Zararlı Yazılım ve Erişilebilirlik Yetkisi Alan Uygulama',
  'pages.parameterslist.value.ess.4': 'Uzaktan Erişim Uygulaması',
  'pages.parameterslist.value.ess.5': 'Uzaktan Erişim Uygulaması ve Erişilebilirlik Yetkisi Alan Uygulama',
  'pages.parameterslist.value.ess.6': 'Uzaktan Erişim Uygulaması ve Zararlı Yazılım',
  'pages.parameterslist.value.ess.7': 'Tüm Durumlar',
  'pages.parameterslist.value.callst.1': 'Arama verisini elde eder',
  'pages.parameterslist.value.callst.3': 'Veriyi backend\'e gönderir',
  'pages.parameterslist.value.callst.7': 'Veriyi backend\'e gönderir ve SDK aracılığıyla mobil uygulamayı bilgilendirir',
  'pages.parameterslist.value.ham.49': 'Bilinmeyen kaynaklardan yüklenen tüm uygulamalar için aktif',
  'pages.parameterslist.value.ham.56': 'Tüm cihazlar için aktif',
  'pages.parameterslist.showParams': 'Parametreleri Göster',
  'pages.parameterslist.parameters': 'Parametreler',
  'pages.parameterslist.message': 'Eğer bu listede olup sizde olmayan bir parametreyi aktifleştirmek istiyorsanız Codevo ile iletişime geçin.',

  'pages.pipeline.table.id': 'Id',
  'pages.pipeline.table.description': 'Tanım',
  'pages.pipeline.table.status': 'Durum',

  'pages.browser.detail.web-pages': 'WEB SAYFALARI',

  'topnav.logout': 'Çıkış yap',
  'topnav.darkmode': 'Karanlık Mod',
  'footer.panelVersion': 'panel versiyon : ',
  'footer.bffVersion': 'bff versiyon : ',
  'footer.intranetVersion': 'intranet versiyon : ',

  /* 04 Anomaly */
  'anomaly.noAnomaly': 'Anomali Yok',
  'anomaly.rooted': 'Root\'lu',
  'anomaly.malwareDetected': 'Zararlı Yazılım Tespit Edildi',
  'anomaly.emulator': 'Emülatör',
  'anomaly.runtimeHookDetected': 'Runtime Hook Tespit Edildi ',
  'anomaly.reverseEngineeringToolDetected': 'Tersine Mühendislik Aracı Tespit Edildi',
  'anomaly.installedFromUnknownSource': 'Bilinmeyen Kaynaktan Kurulum Tespit Edildi',
  'anomaly.appDebuggable': 'Debugging Tespit Edildi',
  'anomaly.fileIntegrityBroken': 'Dosya Bütünlüğü Bozuk',
  'anomaly.simChangeDetected': 'Sim Değişikliği Tespit Edildi',
  'anomaly.remoteAccessAppDetected': 'Uzaktan Erişim Uygulaması Tespit Edildi',
  'anomaly.accessibilityEnabled': 'Erişilebilirlik Etkin',
  'anomaly.developerDetected': 'Geliştirici Tespit Edildi',
  'anomaly.appDetected': 'Hedef Uygulama Tespit Edildi',

  'anomaly.package.anydesk://check': 'AnyDesk',
  'anomaly.package.tvqs://check': 'TeamViewer Quicksupport',
  'anomaly.package.tvsqcustomer1://check': 'TeamViewer',
  'anomaly.package.sandstudio-support://check': 'Airdroid RemoteSupport',
  'anomaly.package.wx43514abbbdfbe22c://check': 'Apower Mirror',
  'anomaly.package.sandstudio-cast://check': 'Airdroid Cast',
  'anomaly.package.itms-beta://check': 'TestFlight',
  'anomaly.package.com.teamviewer.quicksupport.market' : 'TeamViewer Quick Support',
  'anomaly.package.com.teamviewer.host.market' : 'TeamViewer Host',
  'anomaly.package.com.sand.airsos' : 'AirDroid Remote Support',
  'anomaly.package.com.koushikdutta.inkwire' : 'Inkwire Screen Share + Assist',
  'anomaly.package.de.abr.android.avnc' : 'alpha vnc lite',
  'anomaly.package.nfo.oneassist' : 'Screen Share - Remote Assistance',
  'anomaly.package.com.sand.airdroid' : 'Airdroid: Remote Access & File',
  'anomaly.package.screentalk.ghuntur.com' : 'ScreenTalk: Remote Mobile Screen Sharing',
  'anomaly.package.com.logmein.g2acustomer' : 'GoToAssist Customer',
  'anomaly.package.com.logmein.rescueassist' : 'GoToAssist Support - Customer',
  'anomaly.package.com.splashtop.sos' : 'Splashtop SOS - Remote Support',
  'anomaly.package.com.joaomgcd.join' : 'Join by joaoapps',
  'anomaly.package.com.pushbullet.android' : 'Pushbullet: SMS on PC and more',
  'anomaly.package.com.anydesk.anydeskandroid' : 'AnyDesk Remote Desktop',
  'anomaly.package.com.apowersoft.mirror' : 'ApowerMirror- Cast Phone to PC',
  'anomaly.package.com.teamviewer.quicksupport.addon.universal': 'TeamViewer Universal Add-On',
  'anomaly.package.com.anydesk.adcontrol.ad1': 'AnyDesk plugin ad1',
  'anomaly.package.com.pxdworks.typekeeper': 'Type Keeper - Your key logger',
  'anomaly.package.com.com.avast.android.mobilesecurity': 'Avast Antivirüs & Güvenlik',
  'anomaly.package.com.sand.remotesupportaddon': 'AirDroid Control Add-on',

  'anomaly.desc.no': 'Açıklama mevcut değil.',
  'anomaly.desc.installedRemoteAccessApps': 'Yüklü olan uzaktan erişim uygulamaları: ',
  'anomaly.desc.installedAccessibilityApps': 'Yüklü olan erişilebilirlik uygulamaları: ',
  'anomaly.desc.installedTargetApps': 'Yüklü olan hedef uygulamalar: ',
  'anomaly.desc.CDV-SC-1-1': 'Root yönetim uygulamaları tespit edildi.',
  'anomaly.desc.CDV-SC-1-2': 'Potansiyel zararlı root uygulamaları tespit edildi.',
  'anomaly.desc.CDV-SC-1-3': 'Root yazılımları tespit edildi.',
  'anomaly.desc.CDV-SC-1-4': 'Root sistem özellikleri tespit edildi.',
  'anomaly.desc.CDV-SC-1-5': 'Root dosyaları tespit edildi.',
  'anomaly.desc.CDV-SC-1-6': 'Root anahtarları tespit edildi.',
  'anomaly.desc.CDV-SC-1-7': 'Root yazılımları tespit edildi.',
  'anomaly.desc.CDV-SC-1-8': 'Magisk tespit edildi.',
  'anomaly.desc.CDV-SC-1-9': 'Xposed tespit edildi.',
  'anomaly.desc.CDV-iSC-1-1': 'Jailbreak dosyaları tespit edildi.',
  'anomaly.desc.CDV-iSC-1-2': 'Jailbreak dizinleri tespit edildi.',
  'anomaly.desc.CDV-iSC-1-3': 'Jailbreak yönetim uygulamaları tespit edildi.',
  'anomaly.desc.CDV-iSC-1-4': 'Jailbreak portları tespit edildi.',
  'anomaly.desc.CDV-iSC-1-5': 'Jailbreak dizinleri tespit edildi.',
  'anomaly.desc.CDV-SC-2-1': 'Emülatör sistem özellikleri tespit edildi.',
  'anomaly.desc.CDV-SC-2-2': 'Emülatör seri numarası tespit edildi.',
  'anomaly.desc.CDV-SC-2-3': 'Emülatör telefon özellikleri tespit edildi.',
  'anomaly.desc.CDV-SC-2-5': 'Genymotion tespit edildi.',
  'anomaly.desc.CDV-SC-2-6': 'Emülatör sensör özellikleri tespit edildi.',
  'anomaly.desc.CDV-SC-2-7': 'Emülatör batarya özellikleri tespit edildi.',
  'anomaly.desc.CDV-SC-2-8': 'Emülatör işlemci özellikleri tespit edildi.',
  'anomaly.desc.CDV-SC-2-9': 'Emülatör WiFi özellikleri tespit edildi.',
  'anomaly.desc.CDV-SC-2-10': 'Emülatör donanım özellikleri tespit edildi.',
  'anomaly.desc.CDV-iSC-2-1': 'Simülatör ortamı tespit edildi.',
  'anomaly.desc.CDV-iSC-2-2': 'Simülatör modeli tespit edildi.',
  'anomaly.desc.CDV-iSC-2-3': 'Simülatör dosyaları tespit edildi.',
  'anomaly.desc.CDV-SC-3-1': 'Bellekte istenmeyen uygulamalar tespit edildi.',
  'anomaly.desc.CDV-SC-3-2': 'Bellekte istenmeyen kütüphaneler tespit edildi.',
  'anomaly.desc.CDV-SC-3-3': 'SSL Pinning kaldırıldığı tespit edildi.',
  'anomaly.desc.CDV-SC-3-4': 'SSL Pininng kaldırıldığı tespit edildi.',
  'anomaly.desc.CDV-SC-3-5': 'Xposed tersine mühendislik aracı müdahalesi tespit edildi.',
  'anomaly.desc.CDV-SC-3-6': 'Xposed tersine mühendislik aracı müdahalesi tespit edildi.',
  'anomaly.desc.CDV-AA-3-7': 'Sahte lokasyon manipülasyonu tespit edildi.',
  'anomaly.desc.CDV-AA-3-8': 'Android fonksiyonlarına müdahale tespit edildi.',
  'anomaly.desc.CDV-AA-3-9': 'Frida fonksiyonları tespit edildi.',
  'anomaly.desc.CDV-iSC-3-1': 'SSL Pinning kaldırıldığı tespit edildi.',
  'anomaly.desc.CDV-iSC-3-2': 'Bellekte istenmeyen kütüphaneler tespit edildi.',
  'anomaly.desc.CDV-iSC-3-3': 'Cihaz fonksiyonlarına müdahale tespit edildi.',
  'anomaly.desc.CDV-SC-4-1': 'Bellekte Frida tespit edildi.',
  'anomaly.desc.CDV-SC-4-2': 'Cihazda tersine mühendislik araçlarının bulunduğu tespit edildi.',
  'anomaly.desc.CDV-SC-4-3': 'Cihazda Frida aktivitesi tespit edildi.',
  'anomaly.desc.CDV-SC-4-4': 'Cihazda Frida aktivitesi tespit edildi.',
  'anomaly.desc.CDV-SC-4-5': 'Cihazda tersine mühendislik araçlarının bulunduğu tespit edildi.',
  'anomaly.desc.CDV-iSC-4-1': 'Cihazda tersine mühendislik araçlarının bulunduğu tespit edildi.',
  'anomaly.desc.CDV-iSC-4-2': 'Cihazda tersine mühendislik aracı aktivitesi tespit edildi.',
  'anomaly.desc.CDV-iSC-4-3': 'Cihazda Frida aktivitesi tespit edildi.',
  'anomaly.desc.CDV-SC-6-1': 'Uygulamanın bilinmeyen bir kaynaktan yüklendiği tespit edildi.',
  'anomaly.desc.CDV-SC-6-2': 'Uygulamanın bilinmeyen bir kaynaktan yüklendiği tespit edildi.',
  'anomaly.desc.CDV-SC-7-1': 'Uygulamanın debug edilebilir olduğu tespit edildi.',
  'anomaly.desc.CDV-SC-7-2': 'Uygulamaya Java Debugger bağlandığı tespit edildi.',
  'anomaly.desc.CDV-SC-7-4': 'Uygulamaya Debugger bağlandığı tespit edildi.',
  'anomaly.desc.CDV-iSC-7-1': 'Uygulamaya Debugger bağlandığı tespit edildi.',
  'anomaly.desc.CDV-iSC-7-2': 'Uygulamaya Debugger bağlandığı tespit edildi.',
  'anomaly.desc.CDV-SC-8-1': 'Uygulama Dex ve Manifest dosyalarının değiştirildiği tespit edildi.',
  'anomaly.desc.CDV-SC-8-2': 'Uygulama bilgileri değiştirildiği tespit edildi.',
  'anomaly.desc.CDV-SC-8-4': 'Uygulamanın paket ismi veya sertifikasının değiştirildiği tespit edildi.',
  'anomaly.desc.CDV-bSC-8-1': 'Uygulamanın paket ismi veya sertifikasının değiştirildiği tespit edildi.',
  'anomaly.desc.CDV-bSC-8-2': 'Uygulamanın imzalandığı sertifika doğrulanamadı.',
  'anomaly.desc.CDV-iSC-8-1': 'Uygulama bundle dosyaları doğrulanamadı.',
  'anomaly.desc.CDV-iSC-8-2': 'Uygulama profili doğrulanamadı.',
  'anomaly.desc.CDV-SC-9-1': 'Sim kartın değiştiği tespit edildi.',
  'anomaly.desc.CDV-SC-10-1': 'Uzaktan erişim uygulamasının yüklü olduğu tespit edildi.',
  'anomaly.desc.CDV-SC-10-2': 'Uzaktan erişim uygulamasının yüklü olduğu tespit edildi.',
  'anomaly.desc.CDV-AA-10-3': 'Aktif uzaktan erişim tespit edildi.',
  'anomaly.desc.CDV-SC-11-1': 'Aktif erişilebilirlik yetkisi alan uygulama tespit edildi.',
  'anomaly.desc.CDV-SC-12-1': 'ADB tespit edildi.',
  'anomaly.desc.CDV-SC-12-2': 'Developer mod tespit edildi.',
  'anomaly.desc.CDV-iSC-13-1': 'Hedef uygulama tespit edildi.',

  'anomaly.botDetected': 'Bot Tespit Edildi',
  'anomaly.buhTrapDetected': 'BuhTrap Tespit Edildi',
  'anomaly.browserSpoofingDetected': 'Tarayıcı Sahteciliği Tespit Edildi',
  'anomaly.maliciousScriptDetected': 'Kötü Amaçlı Yazılım Tespit Edildi',
  'anomaly.htmlInjectionDetected': 'HTML Injection Tespit Edildi',
  'anomaly.clickJackingDetected': 'Clickjacking Tespit Edildi',
  'anomaly.accessToExternalDomainDetected': 'External Domain\'e Erişim Tespit Edildi',
  'anomaly.virtualMachineDetected': 'Sanal Makine Tespit Edildi',
  'anomaly.debuggingDetected': 'Debugging Tespit Edildi',
  'anomaly.extensionDetected': 'Extension Tespit Edildi',
  'anomaly.remoteDesktopDetected': 'Remote Desktop Tespit Edildi',

  'anomaly.scan.rooted': 'Root Taraması',
  'anomaly.scan.malwareDetected': 'Zararlı Yazılım Taraması',
  'anomaly.scan.emulator': 'Emülatör Taraması',
  'anomaly.scan.runtimeHookDetected': 'Runtime Hook Taraması',
  'anomaly.scan.reverseEngineeringToolDetected': 'Tersine Mühendislik Aracı Taraması',
  'anomaly.scan.installedFromUnknownSource': 'Bilinmeyen Kaynaktan Kurulum Taraması',
  'anomaly.scan.appDebuggable': 'Debug Taraması',
  'anomaly.scan.fileIntegrityBroken': 'Dosya Bütünlüğü Taraması',
  'anomaly.scan.simChangeDetected': 'Sim Değişikliği Taraması',
  'anomaly.scan.remoteAccessAppDetected': 'Uzaktan Erişim Uygulaması Taraması',
  'anomaly.scan.isAccessibilityEnabled': 'Erişilebilirlik Taraması',
  'anomaly.scan.developerDetected': 'Geliştirici Taraması',
  'anomaly.scan.otherApplicationDetected': 'Uygulama Tespiti Taraması',

  'anomaly.scan.botDetected': 'Bot Taraması',
  'anomaly.scan.buhTrapDetected': 'BuhTrap Taraması',
  'anomaly.scan.browserSpoofingDetected': 'Tarayıcı Sahteciliği Taraması',
  'anomaly.scan.maliciousScriptDetected': 'Kötü Amaçlı Yazılım Taraması',
  'anomaly.scan.htmlInjectionDetected': 'HTML Injection Taraması',
  'anomaly.scan.clickJackingDetected': 'Clickjacking Taraması',
  'anomaly.scan.accessToExternalDomainDetected': 'External Domain\'e Erişim Taraması',
  'anomaly.scan.virtualMachineDetected': 'Sanal Makine Taraması',
  'anomaly.scan.debuggingDetected': 'Debugging Taraması',
  'anomaly.scan.extensionDetected': 'Extension Taraması',
  'anomaly.scan.remoteDesktopDetected': 'Remote Desktop Taraması',

  /* Reports */
  'reports.pha.title': 'PHA RAPORLARI',

  /* File Uplaod */
  'pages.file.upload.download.result': 'Sonucu İndir',
  'pages.file.upload.select.files.from.computer': 'Bilgisayardan dosyaları seç',
  'pages.file.upload.upload': 'Dosya Yükle',
  'pages.file.upload.or': 'veya',
  'pages.file.upload.drag.files.here': 'Dosyaları buraya sürükle',
  'pages.file.upload.show.file.history': 'Dosya Geçmişini Göster',
  'pages.file.upload.hide.file.history': 'Dosya Geçmişini Gizle',
  'pages.file.upload.name': 'Ad',
  'pages.file.upload.size': 'Boyut',
  'pages.file.upload.status': 'Durum',

  /* Icon */
  'icon.show': 'İkonu Göster',
  'icon.hide': 'İkonu Gizle',

  'rules.apply-all-changes': 'Tüm Değişiklikleri Uygula',
  'rules.all': 'Tümü',
  'rules.active': 'Aktif',
  'rules.inactive': 'İnaktif',
  'rules.anomaly-types': 'Anomali Türleri',
  'rules.commands': 'Komutlar',
  'rules.search': 'Ara',
  'rules.status': 'Durum',
  'rules.tooltip.crash': 'Octopus ilgili anomaliyi tespit ettiğinde mobil uygulamayı kapatır.',
  'rules.tooltip.block': 'İlgili anomali tespit edildiğinde Secure Server (Seal) o cihaz üzerinden işlem yapılmasına izin vermez. Login veya herhangi bir transaction gerçekleştirilemez.',
  'rules.tooltip.action': 'Müşteriye bilgilendirme metni göstermek için mobil uygulama üzerinde bir popup görüntülenir. FORCE_STOP: Müşteri uygulamayı kullanmaya devam edemez. INFO: Müşteri \"Devam\" butonuna tıklayarak uygulamayı kullanmaya devam edebilir.',
  'rules.tooltip.tag': 'Octopus ve Secure Server herhangi bir aksiyon almaz. Sadece ilgili tarama için cihaza Tag eklenir. ACTION, CRASH ve BLOCK kuralları bu Tag\'ler üzerinden çalıştırılır.',
  'rules.tooltip.dialog': 'Müşteriye bir ya da birden fazla seçeneği olan bir uyarı gönderilir. Mesaj Octopus tarafından gönderilir. FORCE_STOP: Müşteri uygulamayı kullanmaya devam edemez. INFO: Müşteri \"Devam\" butonuna tıklayarak uygulamayı kullanmaya devam edebilir.',
  'rules.tooltip.other': 'Herhangi bir gruba (ACTION, CRASH, BLOCK, TAG) dahil olmayan diğer kurallar.',
};
